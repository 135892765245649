<script setup lang="ts">
import { useAuthorization } from '~/composables/auth/useAuthorization';
import { useAuthStore } from '~/stores/auth/useAuthStore';

const { signOut } = useAuthorization();
const store = useAuthStore();

const onSignOut = async (event: MouseEvent) : Promise<void> => {
    event.preventDefault();

    await signOut();
}
</script>

<template>
    <ClientOnly>
        <a
            v-if="store.isAuthorized"
            variant="tonal"
            class="logout"
            @click.prevent="onSignOut($event)">
            <i class="ri-logout-circle-r-line icon"/>
        </a>
    </ClientOnly>
</template>

<style scoped lang="scss">
    .logout {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        width: 44px;
        height: 44px;
        gap: 8px;
        padding: 4px;
        border-radius: 12px;

        .icon {
            font-size: 20px;
            color: $main-text-color;
        }
    }
</style>