<script setup lang="ts">
import { useBreadcrumbsStore } from '~/stores/navigation/useBreadcrumbsStore';
import Logout from '~/components/shared/Common/Logout.vue';

const breadcrumbsStore = useBreadcrumbsStore();
const isLoading = ref(true);

onMounted(() => {
    isLoading.value = false;
})
</script>

<template>
    <div class="header">
        <div v-if="isLoading">
            <v-skeleton-loader 
                color="#fafafa" 
                type="list-item" 
                minWidth="300px"
            />
        </div>
        <v-breadcrumbs 
            v-else :items="breadcrumbsStore.items" 
            class="breadcrumbs"
        >
            <template #divider>
                <i class="ri-arrow-right-s-line icon"/>
            </template>
            <template #item="{ item }">
                <v-breadcrumbs-item :href="item.href" class="item">
                    {{ item.title }}
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <div class="logout">
            <Logout />
        </div>
    </div>
</template>

<style scoped lang="scss">
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        @include responsive( 'mobile' ) {
            display: none;
        }

        .breadcrumbs {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 12px;
            padding: 0;

            :deep(li) {
                padding: 0;
                margin: 0;
            }

            .item {
                color: var(--Color-Zink-400, $main-text-color);
                font-size: 16px;
                line-height: 18px;
                font-style: normal;
                letter-spacing: -0.13px;

                :deep(a) {
                    color: $main-active-color;

                    &:hover{
                        color: $hover-link-color;
                        text-decoration: none;
                    }
                }
            }

            .icon {
                color: $main-text-color;
                width: var(--Padding-pa-4, 16px);
                height: var(--Padding-pa-4, 16px);
            }
        }

        .logout {
            display: flex;
            @include responsive( 'mobile' ) {
                display: none;
            }
        }
    }
</style>
