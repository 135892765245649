<script setup lang="ts">
import Notification from '@frontend/ui-kit/src/components/Base/Notification.vue';
import Footer from '~/components/shared/Common/Footer.vue';
import Header from '~/components/shared/Common/Header.vue';
import Navbar from '~/components/shared/Common/Navbar.vue';
import { useNotificationStore } from "~/stores/alert/useNotificationStore";

const store = useNotificationStore();

defineProps({
    isError: { type:Boolean, default: false }
});
</script>

<template>
    <v-app class="app">
        <v-layout class="layout">
            <Navbar />
            <div class="main">
                <div v-if="!isError" class="main-container">
                    <Header  />
                    <slot />
                </div>
                <div v-else class="main-container">
                    <slot />
                </div>
                <Footer />
            </div>
            <Notification :messages="store.messages"/>
        </v-layout>
    </v-app>
</template>

<style scoped lang="scss">
    .app {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        flex: 1 0 auto;
        background-color: $main-bg-color;

        :deep(.v-application__wrap) {
            justify-content: center;
            align-items: center;
            align-self: stretch;
        }
    }

    .layout {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        flex: 1 0 auto;
        
        @include responsive( 'mobile' ) { 
            flex-direction: column;
        }
    }

    .main {
        display: flex;
        padding: var(--Padding-pa-6, 24px);

        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        gap: var(--Padding-pa-8, 32px);
        flex: 1 0 0;
        
        @include responsive( 'mobile' ) { 
            padding: 0;
        }
        @include responsive( 'desktop' ) {
            max-width: 1000px;
            align-items: flex-start;
            justify-content: flex-start;
        }
        
        .main-container {
            display: flex;
            flex-direction: column;
            gap: var(--Padding-pa-6, 24px);
            height: 100%;
            width: 100%;

            border-radius: 32px;
            background: var(--Color-Zink-50, $main-card-color);
            box-shadow: 0px 10px 40px -20px rgba(0, 0, 0, 0.10);

            @include responsive( 'mobile' ) {
                max-width: 640px;
                padding: 32px var(--Padding-pa-6, 24px);
            }
            @include responsive( 'desktop' ) {
                padding: var(--Padding-pa-6, 24px) 32px;
                gap: var(--Padding-pa-6, 24px);
            }
            @include responsive( 'desktop-small' ) {
                padding: 40px;
            }
            @include responsive( 'desktop-medium' ) {
                padding: 40px 80px;
            }
        }
    }
</style>