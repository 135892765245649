<script setup lang="ts">
import { useAuthStore } from '~/stores/auth/useAuthStore';
import ClientMenu from './Menu/ClientMenu.vue';
import AdminMenu from './Menu/AdminMenu.vue';
import SettlementAdminMenu from './Menu/SettlementAdminMenu.vue';
import SettlementManagerMenu from './Menu/SettlementManagerMenu.vue';

const store = useAuthStore();

const tabsRef = ref<HTMLElement | null>(null);
const tabsContainerRef = ref<HTMLElement | null>(null);
const isScrollable = ref(false);
const showScrollLeft = ref(false);
const showScrollRight = ref(false);
const isLoading = ref(true);

onMounted(() => {
    updateScrollButtons();
    window.addEventListener('resize', updateScrollButtons);
    isLoading.value = false;

    // Вызов scrollToActive в следующем тике
    nextTick(() => {
        scrollToActive();
    });
});

const updateScrollButtons = () => {
    if (tabsRef.value && tabsContainerRef.value) {
        isScrollable.value = tabsRef.value.scrollWidth > tabsContainerRef.value.clientWidth;
        showScrollLeft.value = tabsRef.value.scrollLeft > 0; // Показываем стрелку влево, если не в начале
        showScrollRight.value = tabsRef.value.scrollLeft < (tabsRef.value.scrollWidth - tabsContainerRef.value.clientWidth); // Показываем стрелку вправо, если не в конце
    }
};

const scrollLeft = () => {
    if (tabsRef.value) {
        tabsRef.value.scrollBy({ left: -100, behavior: 'smooth' });
        updateScrollButtons(); // Обновляем состояние кнопок после скролла
    }
};

const scrollRight = () => {
    if (tabsRef.value) {
        tabsRef.value.scrollBy({ left: 100, behavior: 'smooth' });
        updateScrollButtons(); // Обновляем состояние кнопок после скролла
    }
};

const scrollToActive = () : void => {
    const element = (document.querySelectorAll('a.active')[0])?.parentElement?.lastElementChild;
    if(!element) {
        return;
    }

    const active = ref<Element | null>(element);
    active.value?.scrollIntoView({ behavior: 'smooth' });
}

watch(tabsRef, updateScrollButtons);
</script>

<template>
    <v-skeleton-loader
        v-if="isLoading"
        color="#f4f4f5"
        class="loader"
        type="heading, list-item, list-item, list-item, heading, list-item, list-item"/>
    <v-skeleton-loader
        v-if="isLoading"
        color="#f4f4f5"
        class="mobile-loader"
        type="list-item"/>

    <div 
        v-if="!isLoading" 
        ref="tabsContainerRef" 
        class="menu-list-group" 
        :class="{ scrollable: isScrollable }"
    >
        <button 
            :class="{ visible: showScrollLeft }" 
            class="scroll-left" 
            @click="scrollLeft"
        >
            <i class="ri-arrow-left-s-line"/>
        </button>

        <div ref="tabsRef" class="menu-list">
            <ClientMenu 
                v-if="store.isClient" 
                :userId="store.userId!"/>
            <AdminMenu 
                v-if="store.isAdministrator"   
                :userId="store.userId!"/>
            <SettlementAdminMenu 
                v-if="store.isSettlementAdministrator" />
            <SettlementManagerMenu 
                v-if="store.isSettlementManager" />
        </div>
            
        <button 
            :class="{ visible: showScrollRight }" 
            class="scroll-right" 
            @click="scrollRight"
        >
            <i class="ri-arrow-right-s-line"/>
        </button>
    </div>
</template>

<style scoped lang="scss">
    .loader{
        display: flex;
        @include responsive( 'mobile' ) {
            display:none;
        }
    }
    .mobile-loader {
        display:none;
        width: 100%;
        @include responsive( 'mobile' ) {
            display: flex;
        }
    }

    .menu-list-group {
        display: flex;
        flex: inherit;
        overflow: hidden;

        @include responsive( 'mobile' ) {
            flex-direction: row;
            width: 100%;
            max-width: 640px;
            position: relative;
            overflow: auto;
            -ms-overflow-style: none; /*// IE 10+*/
            overflow: -moz-scrollbars-none;/*// Firefox*/
            padding: var(--Padding-pa-2, 8px) var(--Padding-pa-4, 16px) 0px var(--Padding-pa-4, 16px);
            &::-webkit-scrollbar {
                display: none; /*Safari and Chrome*/
            }
        }

        .menu-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Padding-pa-6, 24px);

            align-self: stretch;
            background-color: $main-bg-color;

            @include responsive( 'mobile' ) {
                white-space: nowrap;
                flex-direction: row;
                gap: 0;

                overflow-x: auto; /* Позволяем горизонтальный скролл */
                scroll-behavior: smooth; /* Плавный скролл */
                -webkit-overflow-scrolling: touch;
                scrollbar-width: thin;
            }
        }
        
        .scroll-left {
            padding: 5px;
            color: var(--Color-Zink-500, $secondary-text-color);
            opacity: 0;
            display: none;

            @include responsive( 'mobile' ) {
                display: flex;
            }
        }

        .scroll-right {
            padding: 5px;
            color: var(--Color-Zink-500, $secondary-text-color);
            opacity: 0;
            display: none;

            @include responsive( 'mobile' ) {
                display: flex;
            }
        }

        .visible {
            opacity: 1;
        }
    }
</style>